
//Header
//main content
//            <img src={HeaderPic} className="App-logo" alt="logo" />

//Footer

import './App.css';
import TableChart from './Components/TableChart';
import Calculator from './Components/Calculator';

import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';



function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/" component={Calculator} />
        </Switch>
        </div>s
    </Router>
  );
}

export default App;


