
/*

REMBER, IF THE SOFTWARE YOU PUT OUT TO THE WORLD IS PERFECT YOU WAITED TO LONG

To-Do's

Double check MATH!

Need to get DELETE FUNCTIONALITY WORKING FOR TABLE!

Finallize what data belongs in the table and 


Clean up all commented code

    //let zipped = monthlyPayment.map(function(e, i) {
    // return [e, taxAdjustedPayment[i]];
    //});
*/


import React, { useState } from 'react';
import { TableContainer, TableHead, TableBody, TableCell, TableRow, Table, TextField, Button } from '@material-ui/core';
import Chart from './Chart'
import MortgageCalcResultsTable from './MortgageCalcResultsTable'


export default function TableChart(){
 
    // Refactoring so that each text box has a state associated with it.
    const [loanAmount, setLoanAmount] = useState();
    const [interestRate, setInterestRate] = useState();
    const [termLength, setTermLength] = useState();
    const [inflationRate, setInflationRate] = useState();
    const [taxRate, setTaxRate] = useState();

    //Calculated data that I want to store
    const [monthlyPayment, setMonthlyPayment] = useState(0);
    const [simpleTotalCost, setSimpleTotalCost] = useState(0);
    const [inflationAdjustedMonthlyPayment, setInflationAdjustedMonthlyPayment] = useState(0);
    const [inflationAdjustedTotalCost, setInflationAdjustedTotalCost] = useState(0);
    const [taxAdjustedPayment, setTaxAdjustedPayment] = useState(0);
    const [count, setCount] = useState(0);

    
    //Data structure for table
    const [tableData, setTableData] = useState([])

    // helper functions
    function createData(counttt, payment, inflationAdjustedPayment, simpleTotalCost, inflationAdjustedTotalCost, taxInflationAdjustedTotalCost) {
      return {counttt, loanAmount, interestRate, termLength, inflationRate, taxRate, payment, inflationAdjustedPayment, simpleTotalCost, inflationAdjustedTotalCost, taxInflationAdjustedTotalCost};
    }

    function computeMontlyPayment(monthlyInterestRate, numberOfMonths){
        //calculation for monthly payment 
        let mP = (monthlyInterestRate*loanAmount)/(1 - Math.pow((1 + monthlyInterestRate), - numberOfMonths));
        return mP;
    }

    function computeInflationAdjustedMonthlyPayment(monthlyInflationRate, numberOfMonths, newMonthlyPayment){
        let inflationAdjustedMonthlyPayment = ((newMonthlyPayment - newMonthlyPayment*Math.pow((1 + monthlyInflationRate), - numberOfMonths))/monthlyInflationRate)/numberOfMonths;
        return inflationAdjustedMonthlyPayment;
    }

    function computeTaxAdjustedTotalCost(monthlyInterestRate, numberOfMonths, monthlyInflationRate, taxRatePercent, newMonthlyPayment, newInflationAdjustedMonthlyPayment){
        let remainingPrinciple = [];
        let interestPayment = 0;
        let lAmount = loanAmount;

        for (let i = 0; i < numberOfMonths; i ++)
        {
            remainingPrinciple.push(lAmount);
            interestPayment = lAmount*monthlyInterestRate;
            lAmount = lAmount - (newMonthlyPayment - interestPayment);
        }

        let totalDeduction = 0;
        let jDeduction = 0;

        for (let j = 0; j < numberOfMonths; j ++)
        {
            jDeduction = remainingPrinciple[j]*monthlyInterestRate*taxRatePercent*(1 + monthlyInflationRate)**-j;
            totalDeduction = totalDeduction + jDeduction;
        }
        let totalDeductionFromTaxes = totalDeduction;



        let totaladjustedpayment = newInflationAdjustedMonthlyPayment*numberOfMonths - totalDeductionFromTaxes;
        return totaladjustedpayment;
    }

    let computeMortgageData = () => 
    {        
        let counttt = count
        setCount(counttt + 1)
        let monthlyInterestRate = (interestRate/12)/100;
        let monthlyInflationRate = (inflationRate/12)/100;
        let numberOfMonths = termLength*12;
        let taxRatePercent = taxRate/100;

        let newMonthlyPayment = computeMontlyPayment(monthlyInterestRate, numberOfMonths);
        setMonthlyPayment(newMonthlyPayment);

        let newInflationAdjustedMonthlyPayment = computeInflationAdjustedMonthlyPayment(monthlyInflationRate, numberOfMonths, newMonthlyPayment); 
        setInflationAdjustedMonthlyPayment(newInflationAdjustedMonthlyPayment);

        let newInflationAdjustedTotalCost = newInflationAdjustedMonthlyPayment*numberOfMonths;
        setInflationAdjustedTotalCost(newInflationAdjustedTotalCost)
        
        let newSimpleTotalCost = newMonthlyPayment*numberOfMonths;
        setSimpleTotalCost(newSimpleTotalCost)

        let newTaxAdjustedPayment = computeTaxAdjustedTotalCost(monthlyInterestRate, numberOfMonths, monthlyInflationRate, taxRatePercent, newMonthlyPayment, newInflationAdjustedMonthlyPayment);
        setTaxAdjustedPayment(newTaxAdjustedPayment);
        
        setTableData(currentData => currentData.concat(
            createData(counttt, newMonthlyPayment, newInflationAdjustedMonthlyPayment, newSimpleTotalCost, newInflationAdjustedTotalCost, newTaxAdjustedPayment)));

    };
    return(
        <div>
            <form name="myForm" id="myForm">

            <TableContainer style={{display:'flex', justifyContent:'center'}}>
                <Table style={{width:'50%', justifyContent:'center'}} size="small">
                    <TableHead>
                    <TableRow>
                        <TableCell><TextField value={loanAmount} onChange={(e)=>setLoanAmount(e.target.value)} label="Loan Amount ($)" margin="normal"  variant="outlined" color="secondary"/></TableCell>
                        
                        <TableCell><TextField value={interestRate} onChange={(e)=>setInterestRate(e.target.value)}label="Interest Rate (%)" margin="normal"  variant="outlined" color="secondary"/></TableCell>
                        
                        <TableCell><TextField value={termLength} onChange={(e)=>setTermLength(e.target.value)} label="Years" margin="normal"  variant="outlined" color="secondary"/></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><TextField value={inflationRate} onChange={(e)=>setInflationRate(e.target.value)} label="Inflation Rate (%)" margin="normal"  variant="outlined" color="secondary"/></TableCell>
                        
                        <TableCell><TextField value={taxRate} onChange={(e)=>setTaxRate(e.target.value)} label="Tax Rate (%)" margin="normal"  variant="outlined" color="secondary"/></TableCell>
                    </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>
            </form>
            <br />
            <div>
                <Button variant="contained" onClick={()=>computeMortgageData()} color="primary">Compute True Cost of Loan</Button>
            </div>
            <br />
            <div>
            </div>
            <br />
            <MortgageCalcResultsTable rows={tableData} />
        </div>
    )
}