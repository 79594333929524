import React from 'react';
import '../App.css';
import TableChart from './TableChart';

function Calculator(){
    return(
        <div>
            <h1>True Cost of a Mortgage</h1>
            <TableChart/>
        </div>
    );
}

export default Calculator;
